import React from "react";
export default function Typewriter(props: any) {
  const [wordIndex, setWordIndex] = React.useState(0);
  const [word, setWord] = React.useState(props.words[wordIndex]);
  const [visibleCharacters, setVisibleCharacters] = React.useState("");
  const [charLength, setCharLength] = React.useState(0);
  const [isIncreasing, setIsIncreasing] = React.useState(true);
  const [delay, setDelay] = React.useState(0);

  const nextWord = () => {
    let nextIndex = wordIndex + 1;
    if (nextIndex >= props.words.length) {
      nextIndex = 0;
    }
    setWordIndex(nextIndex);
    setWord(props.words[nextIndex]);
    props.handelImage(nextIndex);
  };

  const checkLength = () => {
    if (isIncreasing && visibleCharacters.length >= word.length) {
      setDelay(15);
      setIsIncreasing(false);
    } else if (!isIncreasing && visibleCharacters.length === 0) {
      setIsIncreasing(true);
      nextWord();
      setDelay(5);
    }
  };

  const updateLength = () => {
    if (isIncreasing) {
      return setCharLength(charLength + 1);
    }

    setCharLength(charLength - 1);
  };

  const updateCharacters = () => {
    if (delay > 0) {
      setDelay(delay - 1);
    } else {
      checkLength();
      updateLength();
      setVisibleCharacters(word.substring(0, charLength));
    }
  };

  React.useEffect(() => {
    const typingInterval = setInterval(updateCharacters, 100);

    return function () {
      clearInterval(typingInterval);
    };
  });

  return (
    <>
      <span className="text-primary">{visibleCharacters}</span>
    </>
  );
}

import React from "react";
import GradidentCircle from "../GradidentCircle";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ourClient } from "../../components";
import TipTop from "../../assets/Images/Home/TipTop@3x.webp";
import { Fade } from "react-awesome-reveal";

function Client() {
  const SliderRef = React.useRef(null)

  const sliderSettings = {
    slidesToShow: 5,
    arrows: false,
    slidesToScroll: 1,
    speed: 2000,
    touchMove: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <section className="mt-14 relative">
      <div
        className="gradident absolute hidden md:block "
        style={{ top: 0, right: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      
      <h1 className="text-center font-dmserif text-3xl sm:block md:text-4xl lg:text-5xl xl:text-6xl">
        Our Clients
      </h1>
      <Slider {...sliderSettings} className="my-10">
        {ourClient.map((item) => {
          return (
            <div className="flex flex-col  items-center px-2 " key={item.id}>
              <img src={item.image} alt={`${item.alt}`} className="" />
              <h1 className="text-center mt-3">{item.name}</h1>
            </div>
          );
        })}
      </Slider>
     
    </section>
  );
}

export default Client;

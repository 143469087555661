import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GradidentCircle from "../GradidentCircle";
import { ProductList } from "../ProductList/Productlist";
import { useRef } from "react";

function Gallery() {
  const sliderRef = useRef(null);

  function next() {
    // @ts-ignore
    sliderRef?.current?.slickNext();
  }
  function prev() {
    // @ts-ignore
    sliderRef?.current?.slickPrev();
  }
  const sliderSettings = {
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    speed: 500,
    touchMove: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section className="mt-14 mb-10 relative">
      <div
        className="gradident absolute "
        style={{ bottom: 0, right: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      <h1 className="text-center font-dmserif text-3xl sm:block md:text-4xl lg:text-5xl xl:text-6xl">
        Gallery
      </h1>
      <div className="relative overflow-hidden mt-14 p-5">
        <Slider ref={sliderRef} {...sliderSettings}>
          {ProductList.map((image) => {
            if(image.onGallery){
              return (
                <figure key={image.id} className="relative  rounded-lg " style={{ border: "1px solid rgba(0, 136, 139, 0.3)" }}>
                  <img
                    src={`${image.image}`}
                    alt={`${image.alt}`}
                    className=" w-full   h-[300px] md:h-[400px] lg:h-[400px] xl:h-[400px] 2xl:h-[500px] bg-auto"
                  />
                  <div className="absolute  rounded-bl-[1rem] rounded-br-[1rem] bottom-0 left-0 right-0 bg-[black] h-14 flex items-center opacity-60">
                    <h1 className="text-[white] px-5 text-lg">{image.productname}</h1>
                  </div>
                  <figcaption>
                    <h1>
                      <h1 className="text-[0.1px]">{image.dataSource}</h1>
                    </h1>
                  </figcaption>
                </figure>
              );
            }
          })}
        </Slider>
        <img
          src="/arrowleft.png"
          alt=" arrow"
          className="w-8 sm:w-12 cursor-pointer absolute top-[50%] xl:top-[40%] left-9 sm:left-12  "
          style={{ zIndex: "1" }}
          onClick={prev}
        />
        <img
          src="/arrow.png"
          alt=" arrow"
          className="w-8 sm:w-12 cursor-pointer absolute  top-[50%] xl:top-[40%] right-9 sm:right-12"
          style={{ zIndex: "1" }}
          onClick={next}
        />
        <div className="flex justify-center items-center gap-2 sm:gap-5 mt-5">
        <i className="fa-solid text-primary text-xs opacity-60 fa-circle cursor-pointer hover:opacity-90" onClick={prev}></i>
        <i className="fa-solid text-primary text-xs fa-circle cursor-pointer hover:opacity-90" ></i>
        <i className="fa-solid text-primary text-xs opacity-60 fa-circle cursor-pointer hover:opacity-90" onClick={next}></i>
        </div>
      </div>
    </section>
  );
}

export default Gallery;

import React from "react";
import "./textspinner.css";
function TextSpinner() {
  const text = "22 YEARS OF INDUSTRY EXPERTISE SINCE 2000.";
  var deg = 360 / text.length
  var origin = 0;
  const Letter = text.split("").map((char, i) => {
    return (
      <span key={i} style={{ transform: `rotate(${i * 8.6}deg)` }} className="">
        {char}
      </span>
    );
  });

  return (
    <div className="circle2 spinner">
      <div className="dot"></div>
      <div className="text">
        <p>{Letter}</p>
      </div>
    </div>
  );
}

export default TextSpinner;

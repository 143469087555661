import React, { useEffect } from 'react'
import Layout from '../../containers/Layout'
import AboutBanner from '../../features/AboutPage/AboutBanner'

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
   <Layout className='About'>
      <AboutBanner />
   </Layout>
  )
}

export default AboutUs
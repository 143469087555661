import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./App.scss";

import AboutUs from "./pages/About";
import OurClients from "./pages/Clients";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import NotFoundPage from "./pages/NotFoundPage";
import Products from "./pages/Products";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/home"} element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/products" element={<Products />} />
          <Route path="/clients" element={<OurClients />} />
          <Route path="/getquote" element={<Contact />} />
          <Route path="/getquote/:id" element={<Contact />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

function ProductIcon() {
    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      fill="none"
      viewBox="0 0 16 15"
    >
      <path
        fill="#017677"
        d="M1.805 6.453h4.84a.807.807 0 00.806-.806V.807A.807.807 0 006.645 0h-4.84a.807.807 0 00-.807.807v4.84a.807.807 0 00.807.806zm8.066 0h4.84a.807.807 0 00.807-.806V.807A.807.807 0 0014.711 0h-4.84a.807.807 0 00-.806.807v4.84a.807.807 0 00.806.806zM1.805 14.52h4.84a.807.807 0 00.806-.807v-4.84a.807.807 0 00-.806-.806h-4.84a.807.807 0 00-.807.806v4.84a.806.806 0 00.807.807zm10.486 0a3.23 3.23 0 003.227-3.227 3.23 3.23 0 00-3.227-3.226 3.23 3.23 0 00-3.226 3.226 3.23 3.23 0 003.226 3.227z"
      ></path>
    </svg>
    );
  }
  
 export function Scale(){
    return(
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="15"
        fill="none"
        viewBox="0 0 16 15"
      >
        <path
          fill="#017677"
          d="M5.536 8.168H3.947a.227.227 0 01-.226-.227v-.454c0-.125.101-.227.226-.227h1.589V5.445H3.947a.227.227 0 01-.226-.227v-.454c0-.125.101-.227.226-.227h1.589V2.723H3.947a.227.227 0 01-.226-.227v-.454c0-.125.101-.227.226-.227h1.589V.908A.908.908 0 004.628 0H1.906a.908.908 0 00-.908.907v12.706c0 .078.026.148.045.22l4.493-4.492V8.168zm9.075 1.815h-.908v1.588a.227.227 0 01-.227.226h-.454a.227.227 0 01-.226-.226V9.983H10.98v1.588a.227.227 0 01-.226.226H10.3a.227.227 0 01-.227-.226V9.983H8.258v1.588a.227.227 0 01-.227.226h-.454a.227.227 0 01-.226-.226V9.983H6.177l-4.493 4.492c.073.02.143.045.222.045H14.61a.908.908 0 00.908-.907V10.89a.908.908 0 00-.908-.908z"
        ></path>
      </svg>
    )
 }

  export default ProductIcon;
import React from "react";
import "./nav.css";
import { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import navItems from "..";

function Nav() {
  const [toggle, toggleNav] = useState(false);
  const [clientWindowHeight, setClientWindowHeight] = useState(0);
  const [boxShadow, setBoxShadow] = useState(0);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const handleScroll = () => {
    setClientWindowHeight(window.scrollY);
  };

  useEffect(() => {
    let backgroundTransparacyVar = clientWindowHeight / 400;
    if (backgroundTransparacyVar < 1) {
      let boxShadowVar = backgroundTransparacyVar * 0.1;

      setBoxShadow(boxShadowVar);
    }
  }, [clientWindowHeight]);

  const showInMapClicked = () => {
    window.open(
      "https://maps.google.com/maps/place/Universal+Refrigerator+Udhyog/@27.6843489,85.2760105,16.96z/data=!4m5!3m4!1s0x39eb19e127ba4429:0x83c7cea26572924c!8m2!3d27.6843544!4d85.2760299"
    );
  };

  return (
    <div
      className="nav-container"
      style={{
        boxShadow: `rgb(1 118 120 / ${boxShadow}) 0px 0px 30px 6px`,
      }}
    >
      <div className="nav-info-container">
        <div className="nav-info !hidden md:!flex">
          <a href="mailto:universal.udhyog@gmail.com">
            <div className="flex gap-2 items-center text-primary text-base cursor-pointer hover:text-[black]">
              {" "}
              <i className="fa-solid fa-envelope text-[17px] text-primary mt-1"></i>{" "}
              <h1> universal.udhyog@gmail.com</h1>{" "}
            </div>
          </a>
          <div className="flex gap-2 items-center text-primary text-sm ">
            {" "}
            <i className="fa-solid fa-phone text-[17px] text-primary mt-1"></i>{" "}
            <h1> 9851017378 | 9808008216</h1>
          </div>
          <div
            className="flex gap-2 items-center text-primary text-base cursor-pointer hover:text-[black]"
            onClick={showInMapClicked}
          >
            {" "}
            <i className="fa-solid fa-briefcase text-[17px] text-primary mt-1">
              {" "}
            </i>{" "}
            <h1> Kirtipur-02, Kathmandu, Nepal</h1>
          </div>
        </div>
      </div>
      <span className="w-full block h-[1px] bg-[#EAEAEA] w-[83.33%] mx-auto"></span>
      <nav className="nav-bar">
        <Link to="/">
          <img
            src="/logo.webp"
            alt="Logo of Kathmandu based commercial stainless steel equipments manufacturing company named Universal Refrigerator Udhyog."
            className="logo w-[200px] sm:w-[220px] xl:w-[260px] "
          />
        </Link>
        <ul className="menu">
          <li className="item">
            <NavLink
              to={`/`}
              className={({ isActive }) => (isActive ? "link active " : "link")}
            >
              Home
            </NavLink>
          </li>
          {navItems.map((item, index) => {
            return (
              <li className="item" key={index}>
                <NavLink
                  to={`/${item.split(" ").join("")}`}
                  className={({ isActive }) =>
                    isActive ? "link active " : "link"
                  }
                >
                  {item}
                </NavLink>
              </li>
            );
          })}
          <NavLink to={"/getquote"}>
            <button className="btn text-[#fff]">
              get quote
              <i
                className="fa-solid fa-arrow-right pl-2  "
                style={{
                  transform: "rotate(-55deg)",
                }}
              ></i>
            </button>
          </NavLink>
        </ul>

        <button
          className="nav-icon"
          style={{ zIndex: 4 }}
          onClick={() => toggleNav(!toggle)}
        >
          {<span className="line" />}
          <span
            className="line"
            style={{
              width: toggle ? "40%" : "70%",
            }}
          />
          <span className="line" />
        </button>
      </nav>
      {
        <div className="overlay" style={{ height: toggle ? "91vh" : "0" }}>
          <ul
            className="overlay-menu"
            style={{
              transform: toggle ? "translateY(0%)" : "translateY(-200%)",
              transition: "all 0.4s ease-in-out",
            }}
          >
            <li
              className="text-link"
              // style={{ opacity: toggle ? "1" : "0",pointerEvents:toggle?"auto":"none" }}
            >
              <Link
                to={`/`}
                className="link"
                onClick={() => toggleNav((prev) => !prev)}
              >
                Home
              </Link>
            </li>

            {navItems.map((item, index) => {
              return (
                <li
                  className="text-link"
                  // style={{ opacity: toggle ? "1" : "0",pointerEvents:toggle?"auto":"none" }}
                  key={index}
                >
                  <Link
                    to={`/${item.split(" ").join("")}`}
                    className="link"
                    onClick={() => toggleNav((prev) => !prev)}
                  >
                    {item}
                  </Link>
                </li>
              );
            })}
            <li
              className="text-link"
              // style={{ opacity: toggle ? "1" : "0",pointerEvents:toggle?"auto":"none" }}
            >
              <Link
                to={`/getquote`}
                className="link last-link"
                onClick={() => toggleNav((prev) => !prev)}
              >
                get quote
                <i
                  className="fa-solid fa-arrow-right text-primary translate-y-2"
                  style={{
                    transform: "rotate(-55deg)",
                  }}
                ></i>
              </Link>
            </li>
          </ul>
        </div>
      }
    </div>
  );
}

export default Nav;

import React from "react";
import Typewriter from "../Typewriter";
import { Link } from "react-router-dom";
import Dairy from "../../assets/Images/Home/Dairy-new.webp";
import Kitchen from "../../assets/Images/Home/kitchen-new.webp";
import Pharmaceutical from "../../assets/Images/Home/pharmaceutical-new.webp";
import "./home.scss";
import TextSpinner from "../TextSpinner";
import GradidentCircle from "../GradidentCircle";
import { PrimaryButton } from "../../components";

function Banner() {
  const [val, setVal] = React.useState(0);
  function handelImage(data) {
    setVal(data);
  }
  const mountedStyle = {
    animation: "inAnimation 1s ease 0s 1 normal forwards",
  };
  const unmountedStyle = {
    animation: "outAnimation 270ms ease-out",
    animationFillMode: "forwards",
  };
  return (
    <section className="relative">
      <div
        className="spin absolute hidden sm:block"
        style={{
          top: -90,
          right: 40,
        }}
      >
        <TextSpinner />
      </div>
      <div
        className="gradident absolute "
        style={{ top: -249, right: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      <div
        className="gradident absolute "
        style={{ top: 40, left: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      <div className=" mt-10 sm:mt-60 flex flex-col sm:flex-row items-end justify-between ">
        <div>
          <div className="heading sm:leading text-8xl  font-dmserif">
            We manufacture <br />
            steel products
            <br />
            for{" "}
            <Typewriter
              words={["Dairy", "Kitchen", "Pharmaceutical"]}
              handelImage={handelImage}
            />
            <span className="blinking-cursor">|</span>
          </div>
          <p className="banner-paragraph font-text font-inter text-secondary text-lg md:font-paragraph mt-8 ">
            We have been manufacturing high quality stainless steel products for
            commercial kitchen, dairy, and pharmaceutical industries.
          </p>
          <Link to="/getquote" style={{ zIndex: 5 }}>
            <PrimaryButton>Get Quote</PrimaryButton>
          </Link>
        </div>

        <figure className="image-container-home mt-10 sm:mt-0">
          {val === 0 && (
            <img
              src={`${Dairy}`}
              alt="Milk pasteurization machine manufactured in Nepal at affoardable price."
              className="image dairy "
              style={val === 0 ? mountedStyle : unmountedStyle}
            />
          )}
          {val === 1 && (
            <img
              src={`${Kitchen}`}
              alt="Locally made stainless steel lpg gas Chinese burner cooking range at best prices manufactured in Kathmandu."
              className="image kitchen"
              style={val === 1 ? mountedStyle : unmountedStyle}
            />
          )}
          {val === 2 && (
            <img
              src={`${Pharmaceutical}`}
              alt="Stainless steel best quality drying oven for pharmaceutical use made in Kathmandu, Nepal."
              className="image pharmaceutical align-start "
              style={val === 2 ? mountedStyle : unmountedStyle}
            />
          )}
          <figcaption>
            <h1 className="text-[0.1px]">Google</h1>
          </figcaption>
        </figure>
      </div>
    </section>
  );
}

export default Banner;

import React from 'react'
import { Fade } from 'react-awesome-reveal'

const achivementList =[
  {
    id:1,
    year:"20+",
    title:"Years of Industry Experience",
  },
  {
    id:2,
    year:"4000+",
    title:"Projects Completed",
  },
  {
    id:3,
    year:"30+",
    title:"Experienced Staffs",
  },
  {
    id:4,
    year:"500+",
    title:"Clients",
  },
  {
    id:5,
    year:1,
    title:"Year Warranty",
  }
]

function Achivement() {

  return (
    <section className='Achivement my-10'>
      <div className='grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-5'>
      {achivementList.map((item)=>{
        return(
          <Fade key={item.id} direction={"up"} cascade damping={0.2} delay={item.id*50} >
          <div  className='text-primary flex flex-col justify-center items-center gap-2 p-4'>
          <span className='text-5xl sm:text-7xl font-dmserif'>{item.year}</span>
          <span className='text-secondary font-inter text-center '>{item.title}</span>
        </div>
          </Fade>
        )
      })}
      </div>
    </section>
  )
}

export default Achivement
import React from 'react'
import { ourClient } from "../../components";
import'./Clientpage.scss'
import GradidentCircle from "../GradidentCircle"
import { Fade } from "react-awesome-reveal";
function ClientPage() {
  return (
    <section className='md:mt-52 mb-20 relative'>
      <div className="gradident absolute " style={{ top:-249,right:-350, zIndex:2 }}>
          <GradidentCircle/>
      </div>
      <div className="gradident absolute " style={{ top:40,left:-350, zIndex:2 }}>
          <GradidentCircle/>
      </div>
      <div className="gradident absolute " style={{ bottom:-150,right:-350, zIndex:2 }}>
          <GradidentCircle/>
      </div>
         <div className="image-container ">
            {ourClient.map((client)=>{
              return(
                  <Fade direction={"up"} cascade damping={0.2} triggerOnce key={client.id}>
                    <div >
                        <img src={client.image} alt={`${client.alt}`} />
                        <h1 className='text-center my-3'>{client.name}</h1>
                    </div>
                </Fade>
                )
            })}
         </div>
    </section>
  )
}

export default ClientPage
import React from "react";
import Image2 from "../../assets/Images/Home/cover.webp";
import Achivement from "../HomePage/Achivement";
import GradidentCircle from "../GradidentCircle";
import "./about.scss";
import { Fade } from "react-awesome-reveal";

function CoverSection() {
  return (
    <div className="relative flex justify-between items-center gap-10 ">
      <div
        className="gradident absolute "
        style={{ top: -249, right: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      <div
        className="gradident absolute "
        style={{ top: 40, left: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      <div className="about-discription flex flex-col ">
        <Fade direction={"left"} cascade damping={0.2}>
          <div className="about-title text-2xl sm:text-4xl md:text-5xl lg:text-7xl !leading-tight font-dmserif">
            Over 2 Decades In The <br />
            Industry With 4000+ Projects
          </div>
          <div className="about-sub-discription font-inter text-secondary text-lg md:font-paragraph mt-8 sm:pr-5 w-auto">
            We started as a small repair shop in the year 2000 AD with big
            desire to serve our customers. Fast forward 20 years later we have
            grown into manufacturing company and served over 500 customers. We
            aim to be the best manufacturers for steel products and expand our
            presence all over Nepal. Our goal is to emerge as the national
            leader in the manufacturing sector of Nepal. Being one of the oldest
            and experienced in the stainless steel manufacturing sector and
            having worked with small to large customers, we know what the needs
            and wants of our target customers are and we thrive to deliver just
            that to our valuable customers.
          </div>
        </Fade>
      </div>
      <Fade
        direction={"right"}
        cascade
        damping={0.2}
        className="about-image w-full hidden lg:block "
      >
        <img
          src={Image2}
          alt="Commercial Kitchen Designing"
          className="aboutpage-image rounded-l-full "
        />
      </Fade>
    </div>
  );
}

function MissionAndVision() {
  return (
    <div className="mt-10 relative">
      <div
        className="gradident absolute "
        style={{ top: 0, right: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      <Fade direction={"up"} cascade damping={0.2}>
        <div className="Mission flex  items-start  flex-col md:flex-row  gap-5 md:gap-10">
          <h1
            className="Mission-heading text-2xl sm:text-4xl md:text-5xl md:w-[255px] lg:w-[350px]  font-dmserif "
            style={{ flex: "none" }}
          >
            Our Mission
          </h1>
          <p className="mission-description font-inter text-secondary text-lg md:font-paragraph">
            To accelerate the growth of our customers one product at a time.
          </p>
        </div>
        <div className="Vision flex items-start flex-col md:flex-row  my-10 gap-5 md:gap-10">
          <h1
            className="Vision-heading text-2xl sm:text-4xl md:text-5xl  md:w-[255px] lg:w-[350px]  font-dmserif"
            style={{ flex: "none" }}
          >
            Our Vision
          </h1>
          <p className="mission-description font-inter text-secondary text-lg md:font-paragraph">
            To create the best manufacturing company with the state of the art
            technology and generate positive impact in the manufacturing sector
            of Nepal.
          </p>
        </div>
      </Fade>
    </div>
  );
}

function AboutBanner() {
  return (
    <section className="about-banner flex flex-col  mt-10 sm:mt-56">
      <CoverSection />
      <div className="order-last md:order-none">
        <Achivement />
      </div>

      <MissionAndVision />
    </section>
  );
}

export default AboutBanner;

// Dairy
import CreamSeparator from"../../assets/Images/Products/Dairy/Cream Separator.webp"
import IBT from"../../assets/Images/Products/Dairy/Ice Bank Tank (IBT).webp"
import IHM from"../../assets/Images/Products/Dairy/Ice Hardner Machine.webp"
import KhoaMachine from"../../assets/Images/Products/Dairy/Khoa Machine.webp"
import KulfiMachine from"../../assets/Images/Products/Dairy/Kulfi Machine.webp"
import MST from"../../assets/Images/Products/Dairy/Milk Storage Tank.webp"
import PaneerPress from"../../assets/Images/Products/Dairy/Paneer Press.webp"
import Pasteuriser from"../../assets/Images/Products/Dairy/Pasteuriser.webp"
import SDF from"../../assets/Images/Products/Dairy/Steel Deep Freezer.webp"
// Kitchen
import TwoDUC from "../../assets/Images/Products/Kitchen/2 Door Undercounter Chiller.webp"
import TwoDoorUprightChiller from "../../assets/Images/Products/Kitchen/2 Door Upright Chiller.webp"
import ThreeDoorUprightChiller from "../../assets/Images/Products/Kitchen/3 Door Upright Chiller.webp"
import FourDoorUprightChiller from "../../assets/Images/Products/Kitchen/4 Door Upright Chiller.webp"
import ThreeBC from "../../assets/Images/Products/Kitchen/3 Burner Chulho.webp"
import FourBC from "../../assets/Images/Products/Kitchen/4 Burner Chulho.webp"
import BainMarie from "../../assets/Images/Products/Kitchen/Bain Marie.webp"
import BBR from "../../assets/Images/Products/Kitchen/Biryani Burner Range.webp"
import CBC from "../../assets/Images/Products/Kitchen/Chinese Burner Chulho.webp"
import CoalTandoor from "../../assets/Images/Products/Kitchen/Coal Tandoor.webp"
import CommercialChimney from "../../assets/Images/Products/Kitchen/Commercial Chimney.webp"
import commercialChimney from "../../assets/Images/Products/Kitchen/CommercialChimney.webp"
import DisplayCounter from "../../assets/Images/Products/Kitchen/Display Counter-Korean Marble.webp"
import DDO from "../../assets/Images/Products/Kitchen/Double Deck Oven.webp"
import ESI from "../../assets/Images/Products/Kitchen/Electric Steel Induction.webp"
import GasFryer from "../../assets/Images/Products/Kitchen/Gas Fryer.webp"
import KSR from "../../assets/Images/Products/Kitchen/Kitchen Steel Rack.webp"
import SPT from "../../assets/Images/Products/Kitchen/Service Pickup Table.webp"
import SDO from "../../assets/Images/Products/Kitchen/Single Deck Oven.webp"
import SCC from "../../assets/Images/Products/Kitchen/Steel Cash Counter.webp"
import SHP from "../../assets/Images/Products/Kitchen/Steel Hot Plate.webp"
import SO from "../../assets/Images/Products/Kitchen/Steel Oven.webp"
import SST from "../../assets/Images/Products/Kitchen/Steel Service Table.webp"
import SS from "../../assets/Images/Products/Kitchen/Steel Sink.webp"
import TBP from "../../assets/Images/Products/Kitchen/Tilting Bratt Pan.webp"
import TBPP from "../../assets/Images/Products/Kitchen/Tilting Bratt Pan.png"
// Pharmaceutical 
import CMT from"../../assets/Images/Products/Pharmaceuticals/Chemical Mixing Tank.webp"
import MHT from"../../assets/Images/Products/Pharmaceuticals/Material Handling Trolley.webp"
import MSTrolley from"../../assets/Images/Products/Pharmaceuticals/Medical Steel Trolley.webp"
import Sink from"../../assets/Images/Products/Pharmaceuticals/Sink (reverseable offset drain).webp"
import SSMC from"../../assets/Images/Products/Pharmaceuticals/SS Medicine Cabinet.webp"
import SSTable from"../../assets/Images/Products/Pharmaceuticals/SS Table.webp"
import SC from"../../assets/Images/Products/Pharmaceuticals/Steel Cabinet.webp"
import SD from"../../assets/Images/Products/Pharmaceuticals/Steel Drawer.webp"
import SDOven from"../../assets/Images/Products/Pharmaceuticals/Steel Drying Oven.webp"
import STD from"../../assets/Images/Products/Pharmaceuticals/Steel Tray Dryer.webp"
import SWChiller from"../../assets/Images/Products/Pharmaceuticals/Steel Water Chiller.webp"

 export const ProductList = [
  {
    id:0,
    productname: "Service Pickup Table",
    image: SPT,
    category: "Kitchen",
    dataSource:"Indiamart",
    onGallery:true,
    alt:" high quality stainless steel service (pick-up) table used for commercial kitchen use manufactured in Nepal.",
  },
  {
    id:1,
    productname: "Coal Tandoor",
    image: CoalTandoor,
    category: "Kitchen",
    dataSource:"Shiv Tandoor Wala",
    onGallery:false,
    alt:"SS charcoal tandoor at best price in Nepal which is manufactured locally in Kathmandu.",
  },
  {
    id:2,
    productname: "Shawarma Machine",
    image: CommercialChimney,
    category: "Kitchen",
    dataSource:"Indiamart",
    onGallery:true,
    alt:"SS chimney for commercial use manufactured in Nepal.",
  }, 
  {
    id:3,
    productname: "Commercial Chimney",
    image: commercialChimney,
    category: "Kitchen",
    dataSource:"Google",
    onGallery:false,
    alt:"SS chimney for commercial use manufactured in Nepal.",
  },
  
  {
    id:4,
    productname: "Ice Hardner Machine",
    image: IHM,
    category: "Dairy",
    dataSource:"Aajjo",
    onGallery:true,
    alt:"Ice Hardner Machine",
  },
  
  {
    id:5,
    productname: "2 Door Undercounter Chiller",
    image: TwoDUC,
    category: "Kitchen",
    dataSource:"Indiamart",
    onGallery:true,
    alt:"",
  },
  {
    id:6,
    productname: "2 Door Upright Chiller",
    image: TwoDoorUprightChiller,
    category: "Kitchen",
    dataSource:"The Yake Group",
    onGallery:false,
    alt:" High quality SS 2 door upright chiller made and manufactured in Nepal for commercial use.",
  },
  {
    id:7,
    productname: "3 Burner Chulho",
    image: ThreeBC,
    category: "Kitchen",
    dataSource:"Indiamart",
    onGallery:true,
    alt:"Made in Nepal high quality stainless steel lpg operated 3 Burner Chulho (stove) for commercial Kitchen use.",
  },
  
  {
    id:8,
    productname: "Steel Cabinet",
    image: SC,
    category: "Pharmaceuticals",
    dataSource:"Justdial",
    onGallery:true,
    alt:"SS cabinet or steel cabinet for storing medicines, medical injections, files, apron.",
  },
  
  {
    id:9,
    productname: "Display Counter-Korean Marble",
    image: DisplayCounter,
    category: "Kitchen",
    dataSource:"Indiamart",
    onGallery:true,
    alt:"Korean Board body display counter chiller for bakery, dairy, restaurant, and commercial kitchen to enhance your space ambience made in Nepal.",
  },
 
  {
    id:10,
    productname: "Steel Drawer",
    image: SD,
    category: "Pharmaceuticals",
    dataSource:"Amazon",
    onGallery:false,
    alt:"SS drawer manufactured in Nepal for storing small to medium things in it.",
  },
  {
    id:11,
    productname: "Steel Drying Oven",
    image: SDOven,
    category: "Pharmaceuticals",
    dataSource:"Matest",
    onGallery:true,
    alt:"Stainless steel best quality drying oven for pharmaceutical use made in Kathmandu, Nepal.",
  },
  {
    id:12,
    productname: "Steel Tray Dryer",
    image: STD,
    category: "Pharmaceuticals",
    dataSource:"Indiamart",
    onGallery:false,
    alt:" Stainles steel tray dryer, SS tray dryer manufactured in Kathmandu, Nepal.",
  },
  {
    id:13,
    productname: "4 Burner Chulho",
    image: FourBC,
    category: "Kitchen",
    dataSource:"Indiamart",
    onGallery:true,
    alt:"Best quality SS 4 burner chulho (stove) manufactured in Kathmandu for commercial kitchen.",
  },
  {
    id:14,
    productname: "4 Door Upright Chiller",
    image: FourDoorUprightChiller,
    category: "Kitchen",
    dataSource:"The Yale Group",
    onGallery:false,
    alt:"4 door upright chillermanufactured in Nepal using best quality stainless steel.",
  },
  {
    id:15,
    productname: "Bain Marie",
    image: BainMarie,
    category: "Kitchen",
    dataSource:"Amazon",
    onGallery:true,
    alt:"SS Bain marie for warming food at Buffet, commercial kitchen, and parties for long period of time which is made in Kathmandu.",
  },
  {
    id:16,
    productname: "Biryani Burner Range",
    image: BBR,
    category: "Kitchen",
    dataSource:"Indiamart",
    onGallery:false,
    alt:"Best quality Biryani Burner Range (Stove) made in Nepal for cooking Biryani.",
  },
  {
    id:17,
    productname: "Chinese Burner Chulho",
    image: CBC,
    category: "Kitchen",
    dataSource:"Indiamart",
    onGallery:true,
    alt:"Locally made stainless steel lpg gas Chinese burner cooking range at best prices manufactured in Kathmandu.",
  },
  
  {
    id:18,
    productname: "Material Handling Trolley",
    image: MHT,
    category: "Pharmaceuticals",
    dataSource:"Indiamart",
    onGallery:false,
    alt:"SS material handling trolley for day-to-day use in Hospitals, commercial kitchen, and warehouses.",
  },

  {
    id:19,
    productname: "Khoa Machine",
    image: KhoaMachine,
    category: "Dairy",
    dataSource:"Bizimart",
    onGallery:false,
    alt:"Khoa (Khoya) making machine made in Nepal using best quality stainless steel.",
  },
  {
    id:20,
    productname: "Pasteuriser",
    image: Pasteuriser,
    category: "Dairy",
    dataSource:"Moody Process Equipment",
    onGallery:true,
    alt:"Milk pasteurization machine manufactured in Nepal at affoardable price.",
  },
  
  {
    id:21,
    productname: "Steel Deep Freezer",
    image: SDF,
    category: "Dairy",
    dataSource:"Alish Commercial Kitchen",
    onGallery:true,
    alt:"Stainless steel deep freezer made in Nepal for storing packaged dairy products and perishable products like fruits, vegetables, and meat.",
  },
  {
    id:22,
    productname: "Steel Cash Counter",
    image: SCC,
    category: "Kitchen",
    dataSource:"Indiamart",
    onGallery:false,
    alt:"Stainless steel cash counter at affordable prices in Nepal.",
  },
  {
    id:23,
    productname: "Double Deck Oven",
    image: DDO,
    category: "Kitchen",
    dataSource:"Concorde",
    onGallery:true,
    alt:"stainless steel double deck electric oven for commercial use made in Nepal.",
  },
  
  {
    id:24,
    productname: "Electric Steel Induction",
    image: ESI,
    category: "Kitchen",
    dataSource:"Google",
    onGallery:false,
    alt:"Electric run SS induction for commercial kitchen use made in Kathmandu, Nepal.",
  },
  
  {
    id:25,
    productname: "Gas Fryer",
    image: GasFryer,
    category: "Kitchen",
    dataSource:"Aliexpress",
    onGallery:true,
    alt:"commercial gas fryer for deep frying high volume crispy food for commercial kitchen.",
  },
  {
    id:26,
    productname: "Kitchen Steel Rack",
    image: KSR,
    category: "Kitchen",
    dataSource:"Indiamart",
    onGallery:false,
    alt:"SS commercial kitchen rack at best affordable prices in nepal.",
  },
 
  {
    id:27,
    productname: "Cream Separator",
    image: CreamSeparator,
    category: "Dairy",
    dataSource:"Indiamart",
    onGallery:true,
    alt:"Affordable best quality cream separator made in Nepal for separating and removing cream from whole milk.",
  },
  {
    id:28,
    productname: "Single Deck Oven",
    image: SDO,
    category: "Kitchen",
    dataSource:"bakewave",
    onGallery:true,
    alt:"SS Single deck bakery electric oven manufactured in Nepal.",
  },
  {
    id:29,
    productname: "Steel Oven",
    image: SO,
    category: "Kitchen",
    dataSource:"Amazon",
    onGallery:false,
    alt:"stainless steel oven manufactured and supplied by Nepal based manufacturer.",
  },
  {
    id:30,
    productname: "Steel Hot Dog Trailer",
    image: TBPP,
    category: "Kitchen",
    dataSource:"Google",
    onGallery:true,
    alt:"Stainless steel hot dog making machine in nepal.",
  },
 
  {
    id:31,
    productname: "Steel Deep Freezer",
    image: SDF,
    category: "Kitchen",
    dataSource:"Alisha Commercial Kitchen Equipment",
    onGallery:false,
    alt:"Stainless steel deep freezer made in Nepal for storing packaged dairy products and perishable products like fruits, vegetables, and meat.",
  },
  {
    id:32,
    productname: "Steel Hot Plate",
    image: SHP,
    category: "Kitchen",
    dataSource:"Quality Kitchen Equiptment",
    onGallery:true,
    alt:"SS Hot Plate for cooking in commercial kitchen which is locally made. ",
  },
  {
    id:33,
    productname: "Paneer Press",
    image: PaneerPress,
    category: "Dairy",
    dataSource:"RojgarBox",
    onGallery:false,
    alt:"SS 304 manual paneer pressing machine locally made in Nepal.",
  },
 
  {
    id:34,
    productname: "Steel Service Table",
    image: SST,
    category: "Kitchen",
    dataSource:"Butcher Block co.",
    onGallery:true,
    alt:"steel service table used commercially in restaurants, hotels, and kitchen in Nepal.",
  },
  {
    id:35,
    productname: "Steel Sink",
    image: SS,
    category: "Kitchen",
    dataSource:"Braycc",
    onGallery:false,
    alt:"steel sink used in commercial kitchen made locally in Kathmandu.",
  },
 
  
  {
    id:36,
    productname: "Kulfi Machine",
    image: KulfiMachine,
    category: "Dairy",
    dataSource:"Indiamart",
    onGallery:false,
    alt:"Kulfi making machine at best price sold by Nepal based manufacturer.",
  },
  {
    id:37,
    productname: "Chemical Mixing Tank",
    image: CMT, 
    category: "Pharmaceuticals",
    dataSource:"Google",
    onGallery:false,
    alt:"Pharmaceutical chemical mixing steel tank manufactured in Nepal and sold at best affordable price.",
  },
  {
    id:38,
    productname: "Ice Bank Tank (IBT)",
    image: IBT,
    category: "Dairy",
    dataSource:"Bharat Refrigerations",
    onGallery:false,
    alt:"Ice Bank Tank (IBT) or Ice Bank Sytem made in Nepal at best affordable prices.",
  },
  
  {
    id:39,
    productname: "Steel Water Chiller",
    image: SWChiller,
    category: "Pharmaceuticals",
    dataSource:"Indiamart",
    onGallery:false,
    alt:"Nepal made SS water chiller for commercial office spaces, hotels, restaurants, and hospitals.",
  },
  {
    id:40,
    productname: "Milk Storage Tank",
    image: MST,
    category: "Dairy",
    dataSource:"Indiamart",
    onGallery:true,
    alt:"Our Bulk Milk storage Cooler tank in Nepal is mainly used in preserving and storing fresh raw milk.",
  },
  {
    id:41,
    productname: "SS Medicine Cabinet",
    image: SSMC,
    category: "Pharmaceuticals",
    dataSource:"Alibaba",
    onGallery:true,
    alt:"Stainless steel medicine cabinet manufactured in Nepal and sold at best affordable price.",
  },
  {
    id:42,
    productname: "SS Table",
    image: SSTable,
    category: "Pharmaceuticals",
    dataSource:"Aajjo",
    onGallery:false,
    alt:"SS table for daily use in hospital, commercial kitchen, and offices. ",
  },
  

  
  
 ]
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./ProductionPage.css";
import { ProductList } from "../ProductList/Productlist";
import GradidentCircle from "../GradidentCircle";
import { ReactComponent as Dairy } from "../../assets/svg/Dairy.svg";
import { ReactComponent as DairyFill } from "../../assets/svg/Dairyfill.svg";
import { ReactComponent as Kitchen } from "../../assets/svg/kitche.svg";
import { ReactComponent as KitchenFill } from "../../assets/svg/kitchenFill.svg";
import { ReactComponent as Products } from "../../assets/svg/AllProduct.svg";
import { ReactComponent as ProductsFill } from "../../assets/svg/AllProductFill.svg";
import { ReactComponent as Pharmaceuticals } from "../../assets/svg/Pharmaceuticals.svg";
import { ReactComponent as PharmaceuticalsFill } from "../../assets/svg/PharmaceuticalsFill.svg";
import { Fade } from "react-awesome-reveal";

const Divider = () => {
  return <span className="w-full block h-[1px] bg-[#EAEAEA]"></span>;
};

function ProductionPage() {
  const itemList = [
    {
      name: "Dairy",
      icon: Dairy,
      iconFill: DairyFill,
    },
    {
      name: "Kitchen",
      icon: Kitchen,
      iconFill: KitchenFill,
    },
    {
      name: "Pharmaceuticals",
      icon: Pharmaceuticals,
      iconFill: PharmaceuticalsFill,
    },
  ];
  const [isShown, setIsShown] = useState(false);
  const [getIndex, setGetIndex] = useState<Number>();
  const [selectCatagory, setSelectCatagory] = useState<string>("All Products");

  function hoverEvent(data: number) {
    setIsShown((prev) => !prev);
    setGetIndex(data);
  }
  function handelCategory(data: string) {
    setSelectCatagory(data);
  }

  return (
    <section className="Product-container md:mt-44 relative">
      <div
        className="gradident absolute "
        style={{ top: -249, right: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      <div
        className="gradident absolute "
        style={{ top: 40, left: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      <div
        className="gradident absolute "
        style={{ bottom: 40, right: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>

      <Fade direction={"up"} cascade damping={0.2} triggerOnce>
        <Divider />
        <div className="flex justify-start items-center gap-5 md:gap-14 py-4">
          <div
            className="hidden md:flex text-secondary cursor-pointer items-center gap-2"
            onClick={() => handelCategory("All Products")}
            style={{
              color: selectCatagory === "All Products" ? "#017677" : "#959595",
              fontWeight: selectCatagory === "All Products" ? "500" : "400",
            }}
          >
            {selectCatagory === "All Products" ? (
              <ProductsFill />
            ) : (
              <Products />
            )}
            <h1 className="text-xs md:text-lg">All Products</h1>
          </div>
          {itemList.map((item, index) => {
            return (
              <div
                key={index}
                className="text-secondary cursor-pointer flex items-center gap-2"
                onClick={() => handelCategory(item.name)}
                style={{
                  color: selectCatagory === item.name ? "#017677" : "#959595",
                  fontWeight: selectCatagory === item.name ? "500" : "400",
                }}
              >
                {selectCatagory === item.name ? (
                  <item.iconFill />
                ) : (
                  <item.icon />
                )}
                <h1 className="text-xs md:text-lg">{item.name}</h1>
              </div>
            );
          })}
        </div>
        <Divider />
      </Fade>
      <div className="image-container my-10">
        {selectCatagory === "All Products"
          ? ProductList.map((product, index) => {
              return (
                <Fade
                  direction={"up"}
                  cascade
                  damping={0.2}
                  triggerOnce
                  key={product.id}
                >
                  <Link to={`/getquote/${product.id}`}>
                    <figure
                      className="image-frame relative"
                      onMouseEnter={() => hoverEvent(index)}
                      onMouseLeave={() => hoverEvent(0)}
                    >
                      <img
                        src={`${product.image}`}
                        alt={`${product.alt}`}
                        className="rounded-lg w-full h-[85%] md:h-full "
                      />
                      <h1 className="image-quation text-primary !px-5 text-base  underline py-2 md:hidden">
                        <span
                          className="font-medium"
                          style={{ fontStyle: "italic" }}
                        >
                          Get Quotation{" "}
                        </span>
                        <i
                          className="fa-solid hidden sm:inline-block fa-arrow-right text-primary md:text-[white] translate-y-2"
                          style={{
                            transform: "rotate(-55deg)",
                          }}
                        ></i>
                      </h1>

                      {index === getIndex && isShown && (
                        <Link to={`/getquote/${product.id}`}>
                          <div
                            className="hidden md:block md:absolute inset-0 md:flex md:justify-center md:items-center rounded-bl-[0.6rem] rounded-br-[0.6rem] "
                            style={{
                              backgroundColor: "#000",
                              opacity: "0.6",
                              transition: "0.5s ease",
                              zIndex: "1",
                            }}
                          >
                            <h1 className="image-quation underline underline-offset-8 font-medium  md:text-[white]  md:text-xl lg:text-2xl xl:text-3xl cursor-pointer">
                              <span
                                className="font-medium"
                                style={{ fontStyle: "italic" }}
                              >
                                Get Quotation{" "}
                              </span>
                              <i
                                className="fa-solid hidden sm:inline-block fa-arrow-right text-primary md:text-[white] translate-y-2"
                                style={{
                                  transform: "rotate(-55deg)",
                                }}
                              ></i>
                            </h1>
                          </div>
                        </Link>
                      )}
                      <div className="product-name absolute bottom-[2.3rem]  !md:bottom-7 rounded-bl-[0.6rem] rounded-br-[0.6rem] md:bottom-0 left-0 right-0 bg-[black] h-12 flex items-center opacity-70">
                        <h1 className="text-[white] px-5 text-xs md:text-sm lg:text-base xl:text-lg ">
                          {product.productname}
                        </h1>
                      </div>
                      <figcaption>
                        <div className="absolute bottom-0 left-0 p-2">
                          <h1 className="text-[0.1px]">{product.dataSource}</h1>
                        </div>
                      </figcaption>
                    </figure>
                  </Link>
                </Fade>
              );
            })
          : ProductList.map((product, index) => {
              if (product.category === selectCatagory) {
                return (
                  <Fade
                    direction={"up"}
                    cascade
                    damping={0.2}
                    triggerOnce
                    key={product.id}
                  >
                    <Link to={`/getquote/${product.id}`}>
                      <figure
                        className="image-frame relative"
                        onMouseEnter={() => hoverEvent(index)}
                        onMouseLeave={() => hoverEvent(0)}
                      >
                        <img
                          src={`${product.image}`}
                          alt={`${product.alt}`}
                          className="rounded-lg w-full h-[85%] md:h-full "
                        />
                        <h1 className="image-quation text-primary !px-5 text-base  underline py-2 md:hidden">
                          <span
                            className="font-medium"
                            style={{ fontStyle: "italic" }}
                          >
                            Get Quotation{" "}
                          </span>
                          <i
                            className="fa-solid hidden sm:inline-block fa-arrow-right text-primary md:text-[white] translate-y-2"
                            style={{
                              transform: "rotate(-55deg)",
                            }}
                          ></i>
                        </h1>

                        {index === getIndex && isShown && (
                          <Link to={`/getquote/${product.id}`}>
                            <div
                              className="hidden md:block md:absolute inset-0 md:flex md:justify-center md:items-center rounded-[0.6rem] "
                              style={{
                                backgroundColor: "#000",
                                opacity: "0.6",
                                transition: "0.5s ease",
                                zIndex: "1",
                              }}
                            >
                              <h1 className="image-quation underline underline-offset-8 font-medium  md:text-[white]  md:text-xl lg:text-2xl xl:text-3xl cursor-pointer">
                                <span
                                  className="font-medium"
                                  style={{ fontStyle: "italic" }}
                                >
                                  Get Quotation{" "}
                                </span>
                                <i
                                  className="fa-solid hidden sm:inline-block fa-arrow-right text-primary md:text-[white] translate-y-2"
                                  style={{
                                    transform: "rotate(-55deg)",
                                  }}
                                ></i>
                              </h1>
                            </div>
                          </Link>
                        )}
                        <div className="product-name absolute bottom-[2.3rem]  !md:bottom-7 rounded-bl-[0.6rem] rounded-br-[0.6rem] md:bottom-0 left-0 right-0 bg-[black] h-12 flex items-center opacity-70">
                          <h1 className="text-[white] px-5 text-xs md:text-sm lg:text-base xl:text-lg ">
                            {product.productname}
                          </h1>
                        </div>
                        <figcaption>
                          <div className="absolute bottom-0 left-0 p-2">
                            <h1 className="text-[0.1px]">
                              {product.dataSource}
                            </h1>
                          </div>
                        </figcaption>
                      </figure>
                    </Link>
                  </Fade>
                );
              }
            })}
      </div>
    </section>
  );
}

export default ProductionPage;

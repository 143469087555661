import React, { useEffect } from 'react'
import Layout from '../../containers/Layout'
import ProductionPage from '../../features/ProductionPage/ProductionPage'

function Products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout className='Layout'>
        <ProductionPage/>
    </Layout>
  )
}

export default Products
import React from 'react'
import { PrimaryButton } from '../../components'
import { Link } from 'react-router-dom'
import "./notfound.scss"
import GradidentCircle from "../../features/GradidentCircle"
function NotFoundPage() {
  return (
    <div className='h-screen w-full flex flex-col justify-center items-center relative'>
       <div className="gradident absolute " style={{ top:-249,right:-50, zIndex:2 }}>
          <GradidentCircle/>
      </div>
      <div className="gradident absolute " style={{ bottom:-249,left:-50, zIndex:2 }}>
          <GradidentCircle/>
      </div>
    <div className='notfound-container  flex flex-col justify-center items-center' >
      <h1 className='text-secondary text-[200px] font-bold leading'>404</h1>
      <h1 className='text-secondary text-3xl -mt-10'>Page Not Found</h1>
    </div>
      <Link to="/Home">
      <PrimaryButton >Return Home</PrimaryButton>
      </Link>
    </div>
  )
}

export default NotFoundPage
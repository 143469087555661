import React, { useEffect } from 'react'
import Layout from '../../containers/Layout'
import ClientPage from '../../features/ClientPage'
function OurClients() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <ClientPage/>
    </Layout>
  )
}

export default OurClients
import React from "react";
import GradidentCircle from "../GradidentCircle"
function Video() {
  return (
    <section className="video relative mt-5">
      <div
        className="gradident absolute "
        style={{ top: 0, right: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      <div
        className="gradident absolute hidden md:block "
        style={{ bottom: -100, left: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      <div className="hero-underlay  w-full h-full bg-center rounded-xl !border-transparent  bg-cover bg-black">
        <video
          width="320"
          height="240"
          loop
          autoPlay
          muted
          className="w-full h-full object-cover rounded-xl !border-transparent "
          title="Introduction video of Stainless steel equipments manufacturer for commercial kitchen, dairy, and pharmaceutical Nepal."
        >
          <source src="/video/start.mp4" type="video/mp4" />
          <source src="/video/start.mp4" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  );
}

export default Video;

{
  /* <video width="400"  autoplay loop muted>
  <source src="mov_bbb.mp4" type="video/mp4">
  <source src="mov_bbb.ogg" type="video/ogg">
  Your browser does not support HTML video.
</video> */
}

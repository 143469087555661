import React, { useRef, useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { ErrorMessage } from "@hookform/error-message";
import "./contact.css";
import ProductIcon from "../SvgIcons/SvgIcons";
import { ProductList } from "../ProductList/Productlist";
import GradidentCircle from "../GradidentCircle";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useParams } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { Fade } from "react-awesome-reveal";

const showInMapClicked = () => {
  window.open(
    "https://maps.google.com/maps/place/Universal+Refrigerator+Udhyog/@27.6843489,85.2760105,16.96z/data=!4m5!3m4!1s0x39eb19e127ba4429:0x83c7cea26572924c!8m2!3d27.6843544!4d85.2760299"
  );
};
const schema = z.object({
  email: z
    .string({ invalid_type_error: "Email is required" })
    .nonempty({ message: "Email is required" })
    .email(),
  name: z
    .string({ invalid_type_error: "Name is required" })
    .nonempty({ message: "Name is required" })
    .min(2, "Name needs to be longer"),
  phone: z
    .string({ invalid_type_error: "Phone Number is required" })
    .nonempty({ message: "Phone Number is required" })
    .regex(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid"
    ),

  message: z
    .string({ invalid_type_error: "Message is required" })
    .nonempty({ message: "Message is required" })
    .min(2, "Name needs to be longer"),
});
type ContactUsFormData = z.infer<typeof schema>;

function ContactUsForm() {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ContactUsFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur", // "onChange"
  });

  const params = useParams();
  const mydata: any = params?.id;
  const formRef = useRef<any>();
  const categoryRef = useRef<any>();
  const ProductRef = useRef<any>();

  const onSubmit = async (data: ContactUsFormData) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const productList = ProductRef?.current?.getValue();
    const category = categoryRef?.current?.getValue();
    let productResult = productList.map((a: any) => a.label).toString();
    let categoryResult = category.map((a: any) => a.label).toString();
    let abc = {
      ...data,
      product: productResult,
      category: categoryResult,
    };
    sendEmail(abc);

    ProductRef?.current?.clearValue();
    categoryRef?.current?.clearValue();
    reset();
  };

  const sendEmail = (data: any) => {
    // const SERVICE_ID = "service_h92y475";
    const SERVICE_ID = "service_r5b1wna";
    // const TEMPLATE_ID = "template_3mzas1v";
    const TEMPLATE_ID = "template_3gisvjq";
    // const USER_ID = "icWVM9vg6Ddws2u4v";
    const USER_ID = "FJ6IgLkP5F_H3L5YB";
    const templateParams = data;
    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
      .then((e) => console.log({ e }), console.log);
  };
  const [newProductList, setNewProductList] = useState(
    ProductList.map((item) => ({
      id: item.id,
      value: item.productname,
      label: item.productname,
      category: item.category,
    }))
  );

  const productCategory: any = [
    { value: "Dairy", label: "Dairy Equipment" },
    { value: "Kitchen", label: "Kitchen Equipment" },
    { value: "Pharmaceuticals", label: "Pharmaceuticals Equipment" },
  ];

  if (params?.id) {
    const selectCategory = newProductList[mydata].category;
    var selectProductCategory = productCategory.map((item: any) => {
      if (selectCategory == item.value) {
        return item;
      }
    });
  } else {
    selectProductCategory = [];
  }
  const animatedComponents = makeAnimated();

  const [cat, setCat] = useState();

  return (
    <form
      className={"contact-form flex flex-col gap-8 justify-start"}
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}
    >
      <div>
        <div className="relative">
          <input
            type={"text"}
            className={
              "border border-secondary outline-primary  block w-full sm:w-full rounded-md  p-3 px-10 "
            }
            id="name"
            placeholder={"Full Name"}
            {...register("name")}
          />
          <div className="absolute top-3 left-4 text-primary">
            <i className="fa-solid fa-user"></i>
          </div>
        </div>
        <ErrorMessage
          errors={errors}
          name="name"
          as={<p className={"font-normal mt-1 text-danger"} />}
        />
      </div>
      <div>
        <div className="relative">
          <input
            type={"email"}
            className={
              "border border-secondary outline-primary   block w-full sm:w-full rounded-md  p-3 px-10 "
            }
            placeholder={"Email"}
            id="email"
            {...register("email")}
          />
          <div className="absolute top-3 left-4 text-primary">
            <i className="fa-solid fa-envelope"></i>
          </div>
        </div>
        <ErrorMessage
          errors={errors}
          name="email"
          as={<p className={"font-normal mt-1 text-danger"} />}
        />
      </div>
      <div>
        <div className="relative">
          <input
            type={"tel"}
            className={
              "border block w-full sm:w-full rounded-md  p-3 px-10 border-secondary outline-primary  "
            }
            placeholder={"Phone"}
            id={"phone"}
            {...register("phone")}
          />
          <div className="absolute top-3 left-4 text-primary">
            <i className="fa-solid fa-phone"></i>
          </div>
        </div>
        <ErrorMessage
          errors={errors}
          name="phone"
          as={<p className={"font-normal mt-1  text-danger"} />}
        />
      </div>
      <div className="relative">
        <Select
          onChange={(e) => {
            setCat(e);
            const newProduct = ProductList.map((item) => ({
              id: item.id,
              value: item.productname,
              label: item.productname,
              category: item.category,
            })).filter((val) => val.category === e?.value);

            setNewProductList(newProduct);
          }}
          value={cat}
          components={animatedComponents}
          options={productCategory}
          id={"category"}
          placeholder="Category"
          ref={categoryRef}
          defaultValue={selectProductCategory}
          // defaultInputValue={"dairy"}
        />
        <div className="absolute top-4 left-4 text-primary">
          <ProductIcon />
        </div>
      </div>
      <div className="relative">
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          options={newProductList}
          id={"product"}
          placeholder="Product"
          ref={ProductRef}
          defaultValue={[newProductList[mydata]]}
        />
        <div className="absolute top-4 left-4 text-primary">
          <ProductIcon />
        </div>
      </div>

      <div>
        <div className="relative">
          <textarea
            className={
              "!border block w-full sm:w-full rounded-md  p-3  px-10 border-secondary outline-primary  "
            }
            placeholder={"Message"}
            {...register("message")}
          />
          <div className="absolute top-3 left-4 text-primary">
            <i className="fa-solid fa-pen-to-square"></i>
          </div>
        </div>
        <ErrorMessage
          errors={errors}
          name="message"
          as={<p className={"font-normal mt-1 text-danger"} />}
        />
      </div>

      <button
        type={"submit"}
        className={
          "bg-primary text-[white] rounded-md self-start uppercase px-10 py-3  justify-self-start"
        }
      >
        {isSubmitting ? "Get Quote...." : "Get Quote"}
      </button>
    </form>
  );
}

// test

const LetTalkSection = () => {
  return (
    <div className=" my-16 sm:my-0">
      <div className="hidden md:block">
        <h1 className="text-primary font-bold  text-4xl pb-5">Let's talk!</h1>
        <p className="w-2/3 font-inter text-[#042E33] text-xl sm:text-xl">
          Tell us a little bit about yourself and your business and we will soon
          reach out to you.
        </p>
        <br />
        <div className="hidden md:flex items-center my-3">
          <div className="w-2/6 text-center border-b leading-5 mr-2 text-secondary"></div>
          <h2 className="px-5 font-medium text-secondary text-xl">Or</h2>
          <div className="w-2/6 text-center border-b leading-5 mr-2 text-secondary"></div>
        </div>
      </div>
      <div className="lets-call block">
        <h1 className="text-brp-primary font-semibold opacity-60 mt-1 text-3xl ">
          Contact Us Via:
        </h1>
        <div className="contact mt-5">
          <div className="flex items-start gap-5 py-3">
            <i className="fa-solid fa-phone text-[25px] text-primary mt-1"></i>
            <div>
              <h1 className="font-bold opacity-70">Phone Number</h1>
              <h1> 9851017378 | 9808008216</h1>
            </div>
          </div>
          <div className="flex items-start gap-5 py-3">
            <i className="fa-solid fa-envelope text-[25px] text-primary mt-1"></i>
            <div>
              <h1 className="font-bold opacity-70">Email</h1>
              <a className="hover:text-primary" href="mailto:universal.udhyog@gmail.com"> <h1> universal.udhyog@gmail.com</h1></a>
            </div>
       
          </div>
          <div className="flex items-start gap-5 py-3">
            <i className="fa-solid fa-briefcase text-[25px] text-primary mt-1"></i>
            <div>
              <h1 className="font-bold opacity-70">Office Location</h1>
              <h1 className="cursor-pointer hover:text-primary" onClick={showInMapClicked}> Kirtipur-02, Kathmandu, Nepal</h1>
            </div>
          </div>

          <div className="flex items-start gap-5 py-3">
            <i className="fa-solid fa-location-dot text-[25px] text-primary mt-1"></i>
            <div>
              <h1 className="font-bold opacity-70">Site Location</h1>
              <h1 className="">Universal Refrigerator Udhyog</h1>
              <h1 className="">Kirtinagar Tole, Kirtipur-02, </h1>
              <h1 className="">Kirtipur Muncipality, Nepal </h1>
              <h1 className="">Landline Number: +01-4332824</h1>
            </div>
          </div>
          <div className="flex items-start gap-5 py-3">
            <i className="fa-solid fa-globe text-[23px] text-primary mt-1"></i>
            <div>
              <h1 className="font-bold opacity-70">Social Media</h1>
              <a
                target="_blank"
                href="https://www.facebook.com/UniversalRefrigeratorUdhyog/"
                className="flex gap-1 items-center hover:text-primary"
              >
                {" "}
                <i className="fa-brands fa-facebook px-2"></i>
                <h1 className=" hover:text-primary">Facebook </h1>
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/ucsteels/"
                className="flex gap-1 items-center hover:text-primary"
              >
                {" "}
                <i className="fa-brands fa-square-instagram px-2"></i>
                <h1 className=" hover:text-primary">Instagram</h1>
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCaBz6xn3CTGSwzNuwn5hkRw"
                className="flex gap-1 items-center hover:text-primary"
              >
                {" "}
                <i className="fa-brands fa-youtube px-2"></i>
                <h1 className=" hover:text-primary ">Youtube</h1>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function ContactUs() {
  return (
    <div className=" flex flex-col  md:flex-row  justify-between items-start  md:px-0 md:gap-22 md:mt-48 relative">
      <div
        className="gradident absolute "
        style={{ top: -249, right: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      <div
        className="gradident absolute "
        style={{ bottom: -249, left: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>

      <div className="order-last md:order-first px-2 md:px-0">
        <LetTalkSection />
      </div>
      <div
        className={" bg-white p-6 md:p-14  border w-full border-0 "}
        style={{ boxShadow: "0px 0px 43px rgba(0, 0, 0, 0.151)" }}
      >
        <ContactUsForm />
      </div>
    </div>
  );
}

export default ContactUs;

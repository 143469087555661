import React from "react";
import navItems from "..";
import { NavLink } from "react-router-dom";
import './footer.css'
import GradidentCircle from"../../features//GradidentCircle";

const Divider = () => {
  return <span className="w-full block h-[1px] bg-[#EAEAEA]"></span>;
};
const Description = () => {
  return (
    <p className="description text-lg sm:text-xl sm:leading-8 py-6 ">
      Our goal is to manufacture high quality stainless steel products in Nepal
      itself so that we can strengthen the growth of our company and our country
      simultaneously.
    </p>
  );
};
const LinkNav = () => {
  return (
    <div className=" nav-link hidden sm:flex flex items-center gap-5 ">
      {navItems.map((item, index) => {
        return (
          <span className="text-base font-semibold  uppercase text-base " key={index}>
            <NavLink
              to={`/${item.split(" ").join("")}`}
              className={({ isActive }) =>
                isActive
                  ? "footer-navlink underline decoration-primary decoration-4 underline-offset-4"
                  : "footer-navlink"
              }
            >
              {item}
            </NavLink>
          </span>
        );
      })}
       <NavLink to={'/getquote'}>
           <button className="btn">
                  get quote
                  <span
              className="fa-solid fa-arrow-right pl-2  "
              style={{
                transform: "rotate(-55deg)",
              }}
            ></span>
          </button>
          </NavLink>
    </div>
  );
};

const OfficeLocation = () => {
  const showInMapClicked = () => {
    window.open("https://maps.google.com/maps/place/Universal+Refrigerator+Udhyog/@27.6843489,85.2760105,16.96z/data=!4m5!3m4!1s0x39eb19e127ba4429:0x83c7cea26572924c!8m2!3d27.6843544!4d85.2760299");
  };

  return (
    <div>
      <div className="flex items-center justify-start gap-5">
        <i className="fa-solid fa-2x fa-briefcase text-primary"></i>
        <div className="flex flex-col mt-4">
          <span className="footer-title font-bold text-xl text-[#575553] ">
            Office Location:{" "}
          </span>
          <span className="location text-lg sm:text-xl cursor-pointer hover:text-primary" onClick={showInMapClicked}>Kirtipur-02, Kathmandu, Nepal</span>
        </div>
      </div>
    </div>
  );
};

const SiteLocation = () => {
  return (
    <div>
      <div className="flex items-start justify-start gap-5">
        <i className="fa-solid fa-2x fa-location-dot text-primary"></i>
        <div className="flex flex-col items-start">
          <span className="footer-title font-bold text-xl text-[#575553] ">
            Site Location:
          </span>
          <span className="location text-lg sm:text-xl">Universal Refrigerator Udhyog </span>
          <span className="location text-lg sm:text-xl">Kirtinagar Tole, Kirtipur-02, </span>
          <span className="location text-lg sm:text-xl">Kirtipur Municipality, Nepal</span>
          <span className="location text-lg sm:text-xl">Landline Number: +01-4332824</span>
        </div>
      </div>
    </div>
  );

};

const Copyright = () => {
  return (
    <div className="copyright flex justify-between items-center py-3 bg-[#000] text-[#fff] sm:bg-[#fff] sm:text-[#000] sm:w-[80%] sm:mx-auto ">
      <div className="cc-text flex mx-auto sm:mx-0">
      UniversalRefrigeratorUdhyog© 2023<span className="all-right sm:block">, All Rights Reserved</span>
      </div>
      <div className="hidden sm:flex gap-10 ">
        <a target="_blank" href="https://www.facebook.com/UniversalRefrigeratorUdhyog/"><span className="link-title cursor-pointer hover:text-primary">Facebook</span></a>
        <a target="_blank" href="https://www.instagram.com/ucsteels/"><span className="link-title cursor-pointer hover:text-primary">Instagram</span></a>
        <a target="_blank" href="https://www.youtube.com/channel/UCaBz6xn3CTGSwzNuwn5hkRw"><span className="link-title cursor-pointer hover:text-primary">Youtube</span></a>

      </div>
    </div>
  );
};

const SocialMedia=()=>{
  return(
    <div className="flex items-start justify-start gap-5 sm:hidden ">
    <i className="fa-solid fa-2x fa-user text-primary"></i>
    <div className="flex flex-col">
      <span className="footer-title font-bold text-xl text-[#575553] ">
        Social Media
      </span>
      <a target="_blank" href="https://www.facebook.com/UniversalRefrigeratorUdhyog/"><span className="location text-lg sm:text-xl hover:text-primary">Facebook</span></a>
      <a target="_blank" href="https://www.instagram.com/ucsteels/"><span className="location text-lg sm:text-xl hover:text-primary">Instagram</span></a>
      <a target="_blank" href="https://www.youtube.com/channel/UCaBz6xn3CTGSwzNuwn5hkRw"><span className="location text-lg sm:text-xl hover:text-primary">Youtube</span></a>
    </div>
  </div>
  )
}

function Footer() {
  return (
    <div>
    <section className="w-[80%] mx-auto relative">
    <div
        className="gradident absolute "
        style={{ bottom: 0, left: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      <Divider />
      <div className="footer flex flex-col gap-2 sm:flex-row items-start justify-between py-10">
        <div>
          <img src="logo.webp" alt="Logo of Kathmandu based commercial stainless steel equipments manufacturing company named Universal Refrigerator Udhyog." className="footer-logo w-[230px] sm:w-[250px] md:w-[320px]" />
          <Description />
          <LinkNav />
        </div>
        <div className="footer-location flex flex-col gap-3 w-1/2">
          <OfficeLocation />
          <SiteLocation />
          <SocialMedia/>
        </div>
      </div>
      <Divider />
    </section>
      <Copyright />
    </div>
  );
}

export default Footer;

import React from 'react'
import { ReactComponent as KitchenDesign } from "../../assets/svg/KitchenDesigning.svg"
import { ReactComponent as Manufacturing } from "../../assets/svg/Manufacturing.svg"
import { ReactComponent as Installation } from "../../assets/svg/Installation.svg"
import { ReactComponent as PostSalesSupport } from "../../assets/svg/PostSalesSupport.svg"
import { Fade } from 'react-awesome-reveal'


function ChooseUs() {
    const specialityList =[
        {
            id:1,
            title:"Kitchen Designing",
            icon:KitchenDesign,
        },
        {
            id:2,
            title:"Manufacturing",
            icon:Manufacturing,
        },
        {
            id:3,
            title:"Installation",
            icon:Installation,
        },
        {
            id:4,
            title:"Post Sales Support",
            icon:PostSalesSupport,
        },
    ]
  return (
    <section className='choose-us my-24 md:my-14 '>
        <div className='flex flex-col md:flex-row items-start md:items-center justify-between gap-10'>
            <Fade direction={"up"} cascade damping={0.2} className='w-[288px]'>
            <div className='text-3xl flex flex-row md:flex-col md:text-4xl lg:text-4xl xl:text-5xl font-dmserif xl:!leading-[4.5rem]'>
                Why{" "}<br className='hidden md:block'/>
                Choose Us?
            </div>
            </Fade>
           {specialityList.map((item)=>{
            return(
                <Fade direction={"up"} key={item.id} cascade damping={0.2} delay={item.id*100} className="w-full lg:w-[213px]">
                <div  className='flex flex-col justify-center items-center  h-[195px]   px-4 gap-8 rounded-2xl' style={{ 
                    boxShadow: "4px 2px 41px -25px rgba(0,136,139,0.75)"
                 }}>
                    <item.icon/>
                    <h1 className='font-medium text-sm lg:text-lg text-center'>{item.title}</h1>

                </div>
                 </Fade>
            )
           })}
        </div>
    </section>
  )
}

export default ChooseUs
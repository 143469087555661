import clsx from "clsx";
import Gorkarna from ".././assets/Images/Clients/Gorkarna.webp"
import TipTop from ".././assets/Images/Clients/Tip-top.webp"
import Angan from ".././assets/Images/Clients/Angan.webp"
import SitaRam from ".././assets/Images/Clients/Sita-ram.webp"
import Lri from ".././assets/Images/Clients/LRI.webp"
import WhiteHouse from ".././assets/Images/Clients/WhiteHouse.webp"
import KMS from ".././assets/Images/Clients/KMS.webp"
import KMC from ".././assets/Images/Clients/KMC.webp"
import BirHospital from ".././assets/Images/Clients/Bir-hospital.webp"
import Nova from ".././assets/Images/Clients/nova-genetica.webp"
import Curex from ".././assets/Images/Clients/Curex.webp"


const navItems = [ "About Us", "Products","Clients"];
export default navItems

export const ourClient = [
  {
    id:1,
    name:"Gokarna Forest Resort",
    image:Gorkarna,
    alt:"Designed and manufactured stainless steel equipments for its commercial kitchen like bain marie, tandoor, and many more."
  },
  {
    id:2,
    name:"Tip Top",
    image:TipTop,
    alt:"Manufactured stainless steel commercial pick up table, steel chairs, sitting tables, display counter chillers for Tip Top sweets based in Kathmandu."
  },
  {
    id:3,
    name:"Angan Sweets",
    image:Angan,
    alt:" Manufactured high quality Korean Board Stainless steel display counter chiller for Angan Sweets of Kathmandu, Nepal with extensive detailing and smooth finishing."
  },
  {
    id:4,
    name:"Sita Ram Dairy",
    image:SitaRam,
    alt:"Manufactured best quality Chilling Vat and Milk Pasteurization machine for Sita Ram Dairy in Nepal."
  },
  {
    id:5,
    name:"LRI School",
    image:Lri,
    alt:"Designed and manufactured stainless steel service table, steel chairs and sitting tables, steel commercial chimney for the canteen of Learning Realm International School (LRI School) in Syuchatar, Kalanki."
  },
  {
    id:6,
    name:"Himalayan White House Int’l College",
    image:WhiteHouse,
    alt:"Designed the commercial kitchen of Himalayan White House International College based in Kathmandu."
  },
  {
    id:7,
    name:"Kathmandu Model Secondary School",
    image:KMS,
    alt:"Manufactured high quality SS products for the cafeteria of Kathmandu Model Secondary School"
  },
  {
    id:8,
    name:"Bir Hospital",
    image:BirHospital,
    alt:"Manufactured steel products for the canteen of Bir Hospital in New Road (Jamal area)."
  },
  {
    id:9,
    name:"Kathmandu Medical College",
    image:KMC,
    alt:"Manufactured stainless steel products for Kathmandu Medical College canteen."
  },
  {
    id:10,
    name:"Nova Genetica",
    image:Nova,
    alt:"Manufactured high quality stainless steel chemical mixing tank and storage tank for Nova Genetica in Dhading."
  },
  {
    id:11,
    name:"Curex",
    image:Curex,
    alt:"Manufactured best quality Nepal made steel storage tank and bulk milk cooler tank for Curex in Nepal."
  }
  
]

export const PrimaryButton = ({
    className,
    children,
    ...props
  }: React.ComponentPropsWithoutRef<"button">) => {
    return (
      <button
        className={clsx(
          "font-2xl font-medium text-[#fff] uppercase bg-primary px-5 py-3 flex items-center gap-2 mt-10",
          className
        )}
        {...props}
      >
        {children}
        <i
              className="fa-solid fa-arrow-right  "
              style={{
                transform: "rotate(-55deg)",
              }}
            ></i>
      </button>
    );
  };
  
import React from "react";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import CoverImage from "../../assets/Images/Home/cover.webp";
import { PrimaryButton } from "../../components";
import GradidentCircle from "../GradidentCircle";
import "./home.scss";
function About() {
  return (
    <section className="relative flex flex-col sm:flex-row justify-between items-center my-20 sm:mt-32 sm:gap-10 xl:gap-24">
      <div
        className="gradident absolute "
        style={{ top: 0, right: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      <div
        className="gradident absolute "
        style={{ bottom: -100, left: -350, zIndex: 2 }}
      >
        <GradidentCircle />
      </div>
      <span className="text-xl font-dmserif sm:hidden mb-7">
        Over 2 Decades In The Industry With 4000+ Projects
      </span>
      <Fade direction={"left"} cascade damping={0.2}>
        <img
          src={CoverImage}
          alt="Commercial Kitchen Designing"
          className="sm:rounded-r-full sm:h-[300px] ms:h-[400px] xl:h-[410px]"
        />
      </Fade>
      <Fade
        direction={"right"}
        cascade
        damping={0.2}
        className="sm:w-4/5 xl:w-1/2 sm:mt-10"
      >
        <div>
          <span className="hidden text-3xl sm:block md:text-4xl lg:text-5xl 2xl:text-6xl font-dmserif lg:!leading-[4rem]">
            Over 2 Decades In The Industry With 4000+ Projects
          </span>
          <p className="text-lg md:font-paragraph text-secondary mt-4 md:mt-8">
            We have been in the industry for more than 20 years and have
            manufactured more than 4000 stainless steel products for commercial
            kitchen, dairy, and pharmaceutical.
          </p>
          <Link to={'/aboutus'}>
          <PrimaryButton>Read More</PrimaryButton>
          </Link>
        </div>
      </Fade>
    </section>
  );
}

export default About;

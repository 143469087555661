import React from "react";
import clsx from "clsx";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

function Layout({
  className,
  children,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return (
    <div className={clsx("Layout overflow-hidden", className)} {...props}>
      <header className="w-[83.33%] mx-auto">
        <Nav />
      </header>
      <main className="w-[83.33%] mx-auto" style={{ marginTop: "120px" }}>
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default Layout;

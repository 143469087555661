import React, { useEffect } from "react";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import About from "../../features/HomePage/About";
import Achivement from "../../features/HomePage/Achivement";
import Banner from "../../features/HomePage/Banner";
import Client from "../../features/HomePage/Client";
import Gallery from "../../features/HomePage/Gallery";
import Video from "../../features/HomePage/Video";
import ChooseUs from "../../features/HomePage/ChooseUs";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="Home-layout overflow-hidden">
        <header className="w-[83.33%] mx-auto">
          <Nav />
        </header>
        <main className="w-[83.33%] mx-auto" style={{ marginTop: "120px" }}>
          <Banner />
          <ChooseUs />
          <About />
          <Video />
          <Achivement />
            <Client />
        </main>
        <Gallery />
        <Footer />
      </div>
    </div>
  );
}

export default Home;

import React, { useEffect } from 'react'
import { Fade } from 'react-awesome-reveal';
import Nav from '../../components/Nav'
import ContactUs from '../../features/ContactPage/ContactPage'


const Copyright = () => {

  return (
    <div className="copyright flex justify-between items-center py-3 bg-[#000] text-[#fff] sm:bg-[#fff] sm:text-[#000] sm:w-[80%] sm:mx-auto ">
      <div className="cc-text flex mx-auto sm:mx-0">
      UniversalRefrigeratorUdhyog© 2023<span className="all-right sm:block">, All Rights Reserved</span>
      </div>
      <div className="hidden sm:flex gap-10 ">
        <a target="_blank" href="https://www.facebook.com/UniversalRefrigeratorUdhyog/"><span className="link-title cursor-pointer hover:text-primary">Facebook</span></a>
        <a target="_blank" href="https://www.instagram.com/ucsteels/"><span className="link-title cursor-pointer hover:text-primary">Instagram</span></a>
        <a target="_blank" href="https://www.youtube.com/channel/UCaBz6xn3CTGSwzNuwn5hkRw"><span className="link-title cursor-pointer hover:text-primary">Youtube</span></a>

      </div>
    </div>
  );
}; 

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={"Layout overflow-hidden"} >
    <header className="w-[83.33%] mx-auto">
      <Nav/>
    </header>
    <main className="w-[83.33%] mx-auto mb-10 " style={{ marginTop:"150px" }}>      
      <ContactUs />
    </main>
    <Copyright/>
</div>
    
  )
}

export default Contact